'use strict';

// import $ from 'jquery';


(function () {

	function smoothScroll(hash, e) {
		const link = hash.replace("#", "");
		const target = document.getElementById(link);

		if (target !== null) {
			e ? e.preventDefault() : false;
			target.scrollIntoView({
				behavior: 'smooth',
				block: 'center'
			});
		}
	}

	document.addEventListener("DOMContentLoaded", function (event) {

		//Add Flex Video to YouTube & Video Embeds
		document.querySelectorAll('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').forEach(iframe => {
			var wrapper = document.createElement('div');
			wrapper.classList.add('flex-video');

			if (iframe.clientWidth / iframe.clientHeight > 1.5) {
				wrapper.classList.add('widescreen');
			}

			iframe.parentNode.insertBefore(wrapper, iframe);
			wrapper.appendChild(iframe);
		});

		document.querySelectorAll('a.smooth').forEach(el => {
			el.addEventListener('click', e => {
				smoothScroll(e.target.hash, e);
			});
		});

		if (window.location.hash) {
			console.log(window.location.hash);
			smoothScroll(window.location.hash);
		}

	});
})();

// // Add ajax t opage
// console.log('PAGE WORK YEH BABY');
// $('.cat-list_item').on('click', function () {
// 	$('.cat-list_item').removeClass('active');
// 	$(this).addClass('active');

// 	$.ajax({
// 		type: 'POST',
// 		url: '/wp-admin/admin-ajax.php',
// 		dataType: 'html',
// 		data: {
// 			action: 'filter_projects',
// 			category: $(this).data('slug'),
// 		},
// 		success: function (res) {
// 			$('.project-tiles').html(res);
// 		}
// 	})
// });