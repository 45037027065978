'use strict';

let instance;

export default class Header {

	constructor(el) {
		if(instance){
			return instance;
		}

		instance = this

		this.el = el.querySelector('.uk-container');

	}

	getHeight() {
		return this.el.offsetHeight;
	}

	isTransparent() {
		return this.el.classList.contains('transparent');
	}
}
