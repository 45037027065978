//Import Core Styles
import "./app.scss";

import Header from "./core/header/header";
import Hero from "./hero/hero";

//Core JS Functions
import "./core/global";

import css from "uikit/dist/css/uikit.css";
import Icons from "uikit/dist/js/uikit-icons";
import UIkit from "uikit/dist/js/uikit.js";

// import "./about.scss";
import Swiper from "swiper/bundle";

// loads the Icon plugin
UIkit.use(Icons);

//JS Plugins
// import './map/map-controller'
// import './plugins/testimonials'

(function () {
    //Initialise Header
    new Header(document.querySelector("header[uk-sticky]"));

    //Initialise Banner Module
    let banner = document.querySelector(".banner_container");
    if (banner !== null) {
        new Hero(banner);
    }
})();

/**
 * Swiper
 * Add carousel to mutliple areas
 */

// Posts (Case Studies)
var swiper = new Swiper(".post-swiper", {
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
        el: ".swiper-pagination-post",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next-custom-post",
        prevEl: ".swiper-button-prev-custom-post",
    },
    breakpoints: {
        // when window width is >= 640px
        640: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
    },
});
